import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetStarted from "../components/sections/GetStarted"
import heroImage from "../images/img-one-click.png"


import stepImage_0 from '../images/history/card-add.svg'
import stepImage_1 from '../images/history/shield-tick.svg'
import stepImage_2 from '../images/history/card-tick.svg'
import stepImage_3 from '../images/history/tick-square.svg'
import stepImage_4 from '../images/history/emoji-happy.svg'

const Oneclickpayments = () => {
  return (
    <>
    <SEO title={`One click payments - Digimeth.net`} />
    <Layout mainbg={`co-main-dark`}>
    <article>
        <section className="black-hero typical__padding">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Simplify your online </span>
                    <span className="default" style={{ color: `white` }}>checkout process to a single click</span>
                  </h2>
                  <h3 className="description white">
                  This tool allows businesses to increase conversion rate, provide better checkout experience on any device and foster customer loyalty.
                  </h3>
                </div>
              </div>
            </div>
            <div className="right">
              <picture className="fluid__wrapper">
                <img alt="hero" src={heroImage} />
              </picture>
            </div>
          </div>
        </section>

        <section className="alternative_payment-section typical__padding co-bg--grey">
          <div className="site__container grid">
                  <h2 className='centered-mixed-title'>
                    <span className="default">How does</span>
                    <span className="colorized">{``} one-click {``}</span>
                    <span className="default">payment work?</span>
                  </h2>
                  <ul className='history'>
                        <li className='history-line'>
                          <div className='history-line-side content-line'>
                          <p className='text'>
                          Making the first purchase at your online store or service, a customer fills a payment form with the card details, including card number, date, and CVV code
                          </p>
                          </div>
                          
                          <div className='history-line-step--wrapper'>
                            <div className='history-line-step'>
                                  <span>
                                    1
                                  </span>
                            </div>
                          </div>
                        

                          <div className='history-line-side icon-box'>
                          
                          <img src={stepImage_0} alt="icon"/>
                          </div>
                      
                        </li>

                        <li className='history-line'>
                          <div className='history-line-side content-line'>
                          <p className='text'>
                          Being properly certified by PCI DSS and using a fraud prevention system, Digimeth converts these card details into token and stores it, assigned exclusively to the store.
                          </p>

                          </div>


                          <div className='history-line-step--wrapper'>
                            <div className='history-line-step'>
                                  <span>
                                    2
                                  </span>
                            </div>
                          </div>

                          <div className='history-line-side icon-box'>
                            <img src={stepImage_1} alt="icon"/>
                          </div>
                      
                        </li>


                        <li className='history-line'>
                          <div className='history-line-side content-line'>
                          <p className='text'>
                          For subsequent payments a customer just needs to select the stored card and trigger a one-click payment - pressing the “buy” button or any other action.
                          </p>

                          </div>
                          <div className='history-line-step--wrapper'>
                            <div className='history-line-step'>
                                  <span>
                                   3
                                  </span>
                            </div>
                          </div>

                          <div className='history-line-side icon-box'>
                          <img src={stepImage_2} alt="icon"/>
                          </div>
                      
                        </li>


                        <li className='history-line'>
                          <div className='history-line-side content-line'>
                          <p className='text'>
                          A charging request is sent and checked. Transaction processed. Done!
                          </p>

                          </div>

                          <div className='history-line-step--wrapper'>
                            <div className='history-line-step'>
                                  <span>
                                   4
                                  </span>
                            </div>
                          </div>

                          <div className='history-line-side icon-box'>
                          <img src={stepImage_3} alt="icon"/>


                          </div>
                      
                        </li>

                        <li className='history-line'>
                          <div className='history-line-side content-line'>
                          <p className='text'>
                          Your customers will enjoy this simplified payment experience.
                          </p>

                          </div>

                          <div className='history-line-step--wrapper'>
                            <div className='history-line-step'>
                                  <span>
                                   5
                                  </span>
                            </div>
                          </div>

                          <div className='history-line-side icon-box'>
                          <img src={stepImage_4} alt="icon"/>

                          </div>
                      
                        </li>
                  </ul>
          </div>
        </section>

        {/* <section className="alternative_payment-section typical__padding co-bg--grey mt-100">

        <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">How to</span>
                    <span className="default"> chose?</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="right">
                <p className="green_box--description">
                Alternative payments open the world of prepaid cards, mobile payments, e-wallets, bank transfers, etc. Despite mainstream payment methods, like PayPal, merchants can also integrate more local solutions - iDEAL, Sofort etc. 
                </p>
                <br/>
                <p className="green_box--description">
The merchant's choice depends on the working area and local customers' behavioral habits. When the analysis is done, it remains the case for small - complete simple API tech integration.
                </p>
            </div>
          </div>
        </section> */}
        <GetStarted isGetStarted={true} title="Build loyalty and increase customer retention with one-click payments!"/>
        </article>
    </Layout>
    </>
  )
}

export default Oneclickpayments